// IDs
const STRIPE_LOADER_STEP_HASH = 'in-process-parenting-ar';
const STRIPE_LOADER = 'loader-0d551698';
const STRIPE_SUBMIT_BUTTON = 'button-02c99028';
const CALENDLY_SUBMIT_BUTTON = 'button-a7d56c37';
const currentUrl = window.location.href;
const urlParams = new URLSearchParams(window.location.search);
const coupon = urlParams.get('promo_code');
const STRIPE_LINK = 'https://buy.stripe.com/fZe9Da5a22rG8DueVQ?prefilled_promo_code='+coupon;

const PAY_BUTTON_ID = 'button-be8ca2f3';
const FIRST_NAME_INPUT = 'id-355ecb83';
const LAST_NAME_INPUT = 'id-8098d2d5';
const EMAIL_INPUT = 'id-fc32175e';

const SLIDER_ID = 'input-68c5808a-container';
const AFFORDED_PRICE_CONTINUE_BUTTON = 'id-d3decb10';
const BUTTON_1 = 'id-df1314e9';
const BUTTON_2 = 'id-1102796c';
const BUTTON_3 = 'button-c56fb9a3';

// Step map
const stepHashes = [
  {
      id: 'lead-parenting-ar',
      value: 'Lead',
      url: 'sawa-team-parenting-ar'
  },
  {
      id: 'sawa-team-parenting-ar',
      value: 'Initiate Checkout',
      url: 'initiate-checkout-parenting-ar'
  },
  {
      id: 'in-process-parenting-ar',
      value: 'Purchase',
      url: 'purchase-parenting-ar'
  },
  {
      id: 'schedule-care-call-parenting-ar',
      value: 'Schedule',
      url: 'thank-you-done-parenting-ar'
  }
];

function wait(delay){
  return new Promise((resolve) => setTimeout(resolve, delay));
}

function fetchRetry(url, delay, tries, fetchOptions = {}) {
  function onError(err){
      triesLeft = tries - 1;
      if(!triesLeft){
          throw err;
      }
      return wait(delay).then(() => fetchRetry(url, delay, triesLeft, fetchOptions));
  }
  return fetch(url,fetchOptions).catch(onError);
}

const runScript = () => {
if (document.getElementById(PAY_BUTTON_ID)) {
  document.getElementById(PAY_BUTTON_ID).onclick = function () {
    String.prototype.toEncodedString = function () {
      var ostr = this.toString().replace(/\s+/g, '');
      var x, nstr = '', len = ostr.length;
      for (x = 0; x < len; ++x) {
        nstr += (255 - ostr.charCodeAt(x)).toString(36).toUpperCase();
      };
      return nstr;
    };
    const email = document.getElementById(EMAIL_INPUT).value.trim();
    const name = document.getElementById(FIRST_NAME_INPUT).value.trim() + "||" + document.getElementById(LAST_NAME_INPUT).value.trim();
    
  //   Convert Arabic to English
  let isArabic = false;
  let newName = '';
  for(var i = 0; i < name.length; i++) {
    if(name[i].charCodeAt() > 1568) {
      isArabic = true;
      newName += String.fromCharCode(name[i].charCodeAt() - 1503);
    } else {
      newName += name[i];
    }
  }
    
    const reference_id = `${newName}||${email}${isArabic ? '||ar' : '||en'}`.toEncodedString();
    const stripe_link = `${STRIPE_LINK}&prefilled_email=${email}&client_reference_id=${reference_id}`;
    window.open(stripe_link, '_self');
  }
}

if (!document.getElementById(STRIPE_LOADER))
  return;

const processing_error = () => {
  document.getElementById(STRIPE_LOADER).style = 'display: none;';
  if (window.location.href.indexOf('blob') === -1) {
    alert('Something went wrong!');
  }
};

if (window.location.href.split('#')[1] === STRIPE_LOADER_STEP_HASH) {
  const params = (new URL(document.location)).searchParams;
  const session_id = params.get('session_id');
  if (!session_id) {
    processing_error();
  } else {
    fetchRetry(`https://63k4qrsh5l.execute-api.us-west-1.amazonaws.com/dev?session_id=${session_id}`, 1000, 10, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(json => {
        String.prototype.fromEncodedString = function () {

          var ostr = this.toString();
          var x, nstr = '', len = ostr.length;

          for (x = 0; x < len; x += 2) {

            nstr += String.fromCharCode(255 - parseInt(ostr.substr(x, 2), 36));

          };
          return nstr;
        };
        const stripe_email = json.email;
        const reference_id = json.client_reference_id;
        const decoded = reference_id.fromEncodedString();
        if (!decoded) {
          processing_error();
          return;
        }
        const elements = decoded.split('||');
        if (elements.length < 3) {
          processing_error();
          return;
        }
        const e_fname = elements[0];
        const e_lname = elements[1];
        const email = elements[2];
        
      //   Convert Arabic to english
        let fname = elements[3] === 'ar' ? '' : e_fname;
        let lname = elements[3] === 'ar' ? '' : e_lname;
        
        if(elements[3] === 'ar') {
          for(var i = 0; i < e_fname.length; i++) {
              fname += String.fromCharCode(e_fname[i].charCodeAt() + 1503);
          }
          for(var i = 0; i < e_lname.length; i++) {
              lname += String.fromCharCode(e_lname[i].charCodeAt() + 1503);
          }
        }

        setTimeout(() => {
            // Set data
            document.querySelector('input[data-label="Stripe Email"]').value = stripe_email;
            document.querySelector('input[data-label="Stripe Name"]').value = json.name;
            for(const element of document.querySelectorAll('input[data-label="Email"]')) {
                element.value = email;
            }
            document.querySelector('input[data-label="First Name"]').value = fname;
            document.querySelector('input[data-label="Last Name"]').value = lname;
            document.querySelector('input[data-label="Promo Code"]').value = json.promo_code;
            document.querySelector('input[data-label="Coupon"]').value = json.coupon;
            document.querySelector('input[data-label="Subscription Date"]').value = moment(json.subscription_date).format('dddd, MMM Do [at] hh:mm a');
            document.querySelector('input[data-label="Stripe JSON"]').value = json.stripe_json;
            document.getElementById(STRIPE_SUBMIT_BUTTON).click();
        }, 1000);
      });
  }
}
}

const fillStep = () => {
    const hash = window.location.href.split('#')[1];
    const stepHash = stepHashes.find(item => item.id === hash);
    const stepInput = document.querySelector('input[data-label="Step"]');
    if (stepHash && stepInput) {
      stepInput.value = stepHash.value;
    }
    const stepURLInput = document.querySelector('input[data-label="Step URL"]');
    if (stepHash && stepURLInput) {
      stepURLInput.value = stepHash.url;
    }
}

const fillScheduleFields = () => {
  const params = (new URL(document.location)).searchParams;
  let start_time = params.get('event_start_time');
  const invitee_first_name = params.get('invitee_first_name');
  const invitee_last_name = params.get('invitee_last_name');
  const invitee_email = params.get('invitee_email');
  const salesforce_uuid = params.get('salesforce_uuid');
  const invitee_timezone = params.get('invitee_timezone');
  const event_url = params.get('event_location');
  const event_created = params.get('event_created_at');

  
  if (start_time) {
      setTimeout(() => {
            // Set data
          start_time = moment(start_time).format('dddd, MMM Do [at] hh:mm a');
          document.querySelector('input[data-label="Start time"]').value = start_time;
          document.querySelector('input[data-label="Invitee First Name"]').value = invitee_first_name;
          document.querySelector('input[data-label="Invitee Last Name"]').value = invitee_last_name;
          document.querySelector('input[data-label="Invitee Email"]').value = invitee_email;
          document.querySelector('input[data-label="SalesForce UUID"]').value = salesforce_uuid;
          document.querySelector('input[data-label="Invitee Timezone"]').value = invitee_timezone;
          document.querySelector('input[data-label="Event URL"]').value = event_url;
          document.querySelector('input[data-label="Event Created"]').value = event_created;
          document.getElementById(CALENDLY_SUBMIT_BUTTON).click();
        }, 1000);
  }
};


window.addEventListener('hashchange', function () {
runScript();
fillStep();
fillScheduleFields();
});

document.addEventListener("DOMContentLoaded", function () {
runScript();
fillStep();
fillScheduleFields();


const radioButtons = document.querySelectorAll('input[type="radio"][name="id-1e908597"]');
const slider = document.getElementById(SLIDER_ID);
slider.classList.add("hide");
let selectedOption = null;
const sliderValueElement = document.querySelector('#input-68c5808a');

// Add event listener to each radio button
radioButtons.forEach(radio => {
    radio.addEventListener('change', event => {
        // Get the selected value
        const selectedValue = event.target.value;
        
        if (selectedValue === 'id-38214752') {
            selectedOption = 0;
            slider.classList.add("hide");
        } else if (selectedValue === 'id-86217d17') {
            selectedOption = 1;
            slider.classList.remove("hide");
        } else if (selectedValue === 'id-538bc1ff') {
            selectedOption = 2;
            slider.classList.add("hide");
        }
    });
});

function retnum(str) { 
    var num = str.replace(/[^0-9]/g, ''); 
    return parseInt(num,10); 
}

document.getElementById(AFFORDED_PRICE_CONTINUE_BUTTON).onclick = function () {
    console.log(selectedOption);
    if(selectedOption !== null) {
        const rangeValue = retnum(sliderValueElement?.value);
        if(selectedOption === 0) {
            document.getElementById(BUTTON_1).click();
        }
        else if(selectedOption === 1 && rangeValue >= 80) {
            document.getElementById(BUTTON_1).click();
        }
        else if(selectedOption === 1) {
            document.getElementById(BUTTON_2).click();
        } else {
            document.getElementById(BUTTON_3).click();
        }
    }
    return false;
}

});


